import gql from 'graphql-tag';
import { globalfunc } from '../../../../shared/GlobalFunction.js';

class UserService {
    readQuery(){
        var query = `query{
            GetUser{
                userid
                username
                mypwd
                myname
                roleid
                rname
                active_flag
                last_login
                created_at
                last_update
                contact_id
                contact_name
            }
        }`;
        return query;
    }

    async getRoleQuery(){
        const query = gql`query{
            GetRole{
              roleid
              rname
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetRole != null){
            for (let i = 0; i < result.data.GetRole.length; i++) {
                var str = { value:result.data.GetRole[i].roleid, 
                            label:result.data.GetRole[i].rname}
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    async GetContactList(){
        var query = gql`query{
            GetContact{
                contact_id
                contact_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetContact != null){
            for (let i = 0; i < result.data.GetContact.length; i++) {
                var str = { value:result.data.GetContact[i].contact_id, 
                            label:result.data.GetContact[i].contact_name}
                arrayData.push(str);
            }
        }
        return arrayData;
    }  

    async deleteQuery(variables){
        const query = gql`mutation($id:String!){
            DeleteUser(UID:$id){
                response
            }
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async addQuery(variables){
        const query = gql`mutation($data:NewUser!){
            CreateUser(input:$data){
                response
            }
        }`
        return await globalfunc.defaultApolloMutationDefault(query, variables);
    }
    
    async editQuery(variables){
        const query = gql`mutation($id:String!,$data:NewUser!){
            UpdateUser(UID:$id,New:$data){
                response
            }
        }`
        return await globalfunc.defaultApolloMutationDefault(query, variables);
    }
}


export default new UserService();